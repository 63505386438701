import React from 'react';
import './Photos.css'; // Import the CSS file
import img1 from '../Images/1.jpeg';
import img2 from '../Images/2.jpeg'; 
import img3 from '../Images/3.jpeg';
import img4 from '../Images/4.jpeg';
import img5 from '../Images/5.jpeg';
import img6 from '../Images/6.jpeg';
import img7 from '../Images/7.jpeg';

import jimg1 from '../Images/J1.jpeg';
import jimg2 from '../Images/J2.jpeg'; 
import jimg3 from '../Images/J3.jpeg';
import jimg4 from '../Images/J4.jpeg';

import christmas1 from '../Images/Christmas/5.jpg';
import christmas2 from '../Images/Christmas/2.jpg';
import christmas3 from '../Images/Christmas/3.jpg';
import christmas4 from '../Images/Christmas/4.jpg';
import christmas5 from '../Images/Christmas/1.jpg';

import ny1 from '../Images/New Year/1.jpg';
import ny2 from '../Images/New Year/2.jpg';
import ny3 from '../Images/New Year/3.jpg';

import spda from '../Images/Sports Day/a.jpg';
import spd1 from '../Images/Sports Day/1.jpg';
import spd2 from '../Images/Sports Day/2.jpg';
import spd3 from '../Images/Sports Day/3.jpg';
import spd4 from '../Images/Sports Day/4.jpg';
import spd5 from '../Images/Sports Day/5.jpg';
import spd6 from '../Images/Sports Day/6.jpg';
import spd7 from '../Images/Sports Day/7.jpg';
import spd8 from '../Images/Sports Day/8.jpg';
import spd9 from '../Images/Sports Day/9.jpg';
import spd10 from '../Images/Sports Day/10.jpg';


function PhotosPage() {
  const photos = [img1, img2, img3, img4, img5, img6, img7];
  const photos2 = [jimg1, jimg2, jimg3, jimg4];
  const photos3 = [christmas1,christmas2,christmas3,christmas4,christmas5]
  const photos4 = [ny1,ny2,ny3];
  const photos5 = [spda,spd1,spd2,spd3,spd4,spd5,spd6,spd7,spd8,spd9,spd10];

  return (
    <div className="photos-page">
      <header className="page-header">
        <h1>All Photos</h1>
      </header>

      <h2 className="event-heading">Christmas Celebration</h2>
      <div className="photo-grid">
        {photos3.map((photo, index) => (
          <div className="photo-item" key={index}>
            <img src={photo} alt={`Photo- ${index + 1}`} />
          </div>
        ))}
      </div>

      <h2 className="event-heading">New Year Celebration</h2>
      <div className="photo-grid">
        {photos4.map((photo, index) => (
          <div className="photo-item" key={index}>
            <img src={photo} alt={`Photo- ${index + 1}`} />
          </div>
        ))}
      </div>

      <h2 className="event-heading">Sports Day 2024</h2>
      <div className="photo-grid">
        {photos5.map((photo, index) => (
          <div className="photo-item" key={index}>
            <img src={photo} alt={`Photo- ${index + 1}`} />
          </div>
        ))}
      </div>

      <h2 className="event-heading">Independence Day 2024</h2>
      <div className="photo-grid">
        {photos.map((photo, index) => (
          <div className="photo-item" key={index}>
            <img src={photo} alt={`Photo- ${index + 1}`} />
          </div>
        ))}
      </div>

      <h2 className="event-heading">Janmashtami Celebration</h2>
      <div className="photo-grid">
        {photos2.map((photo, index) => (
          <div className="photo-item" key={index}>
            <img src={photo} alt={`Photo- ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PhotosPage;
