import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './ImageSlider.css';

import gdimg1 from '../Images/GD-2.JPG';
import gdimg2 from '../Images/GD-1.JPG';
import gdimg3 from '../Images/GD-3.JPG';
import gdimg4 from '../Images/GD-4.JPG';
import gdimg5 from '../Images/GD-5.JPG';
import gdimg6 from '../Images/Slider/1.jpg';
import gdimg7 from '../Images/Slider/2.jpg';
import gdimg8 from '../Images/Slider/3.jpg';

// Importing modules from 'swiper/modules'
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

function ImageSlider() {
  const images = [
    'https://img.staticmb.com/mbcontent/images/crop/uploads/2022/6/housing-society_0_1200.jpg',
    'https://www.commercialproperty.review/wp-content/uploads/2020/08/ATS-Advantage-Apartments-Indirapuram-Ghaziabad.jpg',
    'https://www.shutterstock.com/image-photo/noida-india-january-26-2024-260nw-2418076223.jpg',
    'https://www.housinginternational.coop/wp-content/uploads/drupal/india%202_0.jpg',
    'https://images.mid-day.com/images/images/2024/mar/Relief-for-small-a_d.jpg',
  ];


  const photos = [gdimg6,gdimg7,gdimg8,gdimg1,gdimg2,gdimg3,gdimg4,gdimg5];
  return (
    <div className="slider-container">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop={true}
        // breakpoints={{
        //   1024: {
        //     slidesPerView: 3,
        //   },
        //   768: {
        //     slidesPerView: 2,
        //   },
        //   640: {
        //     slidesPerView: 1,
        //   },
        // }}
      >
        {photos.map((img, index) => (
          <SwiperSlide key={index}>
            <img src={img} alt={`Slide ${index + 1}`} className="slider-image" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default ImageSlider;
