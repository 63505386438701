import React from 'react';
import './Updates.css'; // Import the CSS file

function UpdatesPage() {
  const updates = [
    {
      title: "GENERAL BODY MEETING",
      date: "January 2, 2025",
      description: "We would like to reiterate that a General Body Meeting (GBM) is scheduled to take place on Sunday, 05th January 2025 in D Block Community Hall at 11.30 am."
    },
    {
      title: "Diwali Mela",
      date: "October 26, 2024",
      description: "Join us for the Diwali Mela on 27th October."
    },
    {
      title: "15th August Celebration",
      date: "August 18, 2024",
      description: "Take a Look at the Celebration of Independence day in the photos section."
    },
    {
      title: "Plastic Collection Drive",
      date: "August 17, 2024",
      description: "A plastic Collection drive was conducted in the society for reusing plastic. An intiative to protect the environment."
    },
    // Add more updates as needed
  ];

  return (
    <div className="updates-page">
      <header className="page-header">
        <h1>All Updates</h1>
      </header>

      <div className="updates-list">
        {updates.map((update, index) => (
          <div className="update-card" key={index}>
            <h2 className="update-title">{update.title}</h2>
            <p className="update-date">{update.date}</p>
            <p className="update-description">{update.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UpdatesPage;
